import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/layout';

const AboutWebsite = ({ data }: PageProps<DataType>): JSX.Element => {
  const { ossLicense } = data;
  return (
    <Layout seo={{ title: `オープンソース ライセンス - ${ossLicense.name}` }}>
      <section className="section">
        <div className="container">
          <h1 className="title is-spaced">{ossLicense.name}</h1>
          <p className="block"><a href={ossLicense.repository}>{ossLicense.repository}</a></p>
          <p className="content">{ossLicense.license}</p>
        </div>
      </section>
    </Layout>
  );
};

export default AboutWebsite;

export const pageQuery = graphql`
  query ($name: String!) {
    ossLicense(name: { eq: $name }) {
      name
      repository
      license
    }
  }
`;

type DataType = {
  ossLicense: {
    name: string;
    repository: string;
    license: string;
  };
};
